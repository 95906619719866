import React, { useState, useEffect } from "react";
import closeBtn from '../assets/images/close-button.svg';
import KoalendarLogo from "../assets/images/koalendar.svg";

const InitKoalendar = ({ buttonText, className, children, icon }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Prevent background from scrolling when modal is open
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling when modal is closed
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button className={className} onClick={handleClick}>
                {buttonText} {icon && <img src={KoalendarLogo} alt="koalendar logo" className="calendly-img" />}
                {children}
            </button>

            {isOpen && (
                <div style={popupStyles.overlay}>
                    <div style={popupStyles.modalContainer} onClick={(e) => e.stopPropagation()}>
                        <button style={popupStyles.closeButton} onClick={handleClose}>
                            <img src={closeBtn} alt="close button" />
                        </button>
                        <iframe
                            src="https://koalendar.com/e/meet-with-sogody?embed=true"
                            frameBorder="0"
                            style={popupStyles.iframe}
                            title="Koalendar Embed"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

const popupStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContainer: {
        position: 'relative',
        width: '90vw', // Responsive width
        maxWidth: '1000px', // Maximum width
        height: '90vh', // Responsive height
        maxHeight: '800px', // Maximum height
        background: '#fff', // Ensure the background is visible
        borderRadius: '8px', // Optional: for rounded corners
        overflow: 'hidden',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: '5px',
        right: '10px',
        zIndex: 1001, // Ensure button is above the iframe

    },
    iframe: {
        marginTop: '3%',
        width: '100%',
        height: '100%',
        border: 'none',
        boxShadow: 'none',
        pointerEvents: 'auto', // Ensure the iframe is interactive
    },
};

export default InitKoalendar;