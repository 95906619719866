import React from "react";
import { graphql, useStaticQuery } from "gatsby";


const Announcment = ({setBanner}) =>{

    const data = useStaticQuery(query)
    const {allSanityAnnouncmentBanner:{
        nodes:announcment
    }} = data;

    return (
        announcment?.map((announce) => {
            const { backgroundColor, closingIcon, description, mobileDescription, icon, textAndIcon } = announce;
            return (<div style={{backgroundColor: backgroundColor?.hex}} className="announcment-banner">
                    <img src={icon?.asset?.url} className="announcment-banner-icon" />
                    <p className="announcment-banner-description desktop">
                        {description}
                        {textAndIcon?.map((item) => {
                            const { text, arrowIcon, href } = item;
                            return <a href={href} target="_blank" className="announcment-span" >
                                <span className="announcment-banner-redirect">{text}</span>
                                <img className="announcment-arrow-up-right" src={arrowIcon?.asset?.url} />
                              </a>;
                        })}
                    </p>
                    <p className="announcment-banner-description mobile">
                        {mobileDescription}
                        {textAndIcon?.map((item) => {
                            const { text, arrowIcon, href } = item;
                            return <a href={href} target="_blank" className="announcment-span" >
                                <span className="announcment-banner-redirect">{text}</span>
                                <img className="announcment-arrow-up-right" src={arrowIcon?.asset?.url} />
                              </a>;
                        })}
                    </p>
                <img src={closingIcon?.asset?.url} className="announcment-banner-closeIcon" onClick={() =>setBanner((prevState) => !prevState)} />   
            </div>)
        })
    )
};

export default Announcment;

export const query = graphql`
{
    allSanityAnnouncmentBanner {
      nodes {
        backgroundColor {
          hex
        }
        icon {
          asset {
            url
          }
        }
        description
        mobileDescription
        textAndIcon {
          text
          href
          arrowIcon {
            asset {
              url
            }
          }
        }
        closingIcon {
          asset {
            url
          }
        }
      }
    }
  }
`