import React from 'react';
import { useState } from 'react';
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby";
import Announcment from './AnnouncmentBanner';
import Header from './Header'
import Footer from './Footer'
import '../assets/style.css';


const Layout = ({children}) => {
    const data = useStaticQuery(query)
    const {allSanityAnnouncmentBanner:{
        nodes:announce
    }} = data;

    const slugLocation = useLocation();

    const shouldBeHidden = announce[0]?.urls?.some(url => url?.slug == (slugLocation.pathname));

    const [banner, setBanner] = useState(announce[0]?.show) 

    return (
      <div className="layout-container">

        {(banner && !shouldBeHidden) ? <Announcment setBanner={setBanner} /> : <></>}
        <Header bannerData={(banner && !shouldBeHidden)} />
        <div className={`${(banner && !shouldBeHidden) ? 'spacing' : ''}`}>
          {children}
        </div>
        <Footer />
      </div>
    )
}

export default Layout

export const query = graphql`
{
  allSanityAnnouncmentBanner {
    nodes {
      show
      urls {
        slug
      }
    }
  }
}  
`
