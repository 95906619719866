import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, InputGroup, Image } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { useStaticQuery, graphql } from 'gatsby';
import Select from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import closeBtn from '../assets/images/close-button.svg';
import KoalendarLogo from '../assets/images/koalendar.svg'; // Import Koalendar logo

import { PortableText } from '@portabletext/react';

const schema = yup.object({
    username: yup.string().min(2, 'Name and last name too short.').required('Please write down your name and last name.'),
    email: yup.string().email('Invalid email.').required('Please write down your email address.'),
    company: yup.string().required('Please write down your company name.'),
    reason: yup.string().required('Please write down your reason.'),
});

const settings = {
    dots: true,
    arrows: false,
    centerMode: true,
    autoplay: true,
    centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                centerPadding: "60px",
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerPadding: "30px",
            }
        }
    ]
};

const selectStyles = {
    option: (styles) => ({
        ...styles,
        color: '#000',
        fontSize: "14px",
        fontFamily: "HelveticaNeueLT Pro 55 Roman",
        backgroundColor: "#fff",
        cursor: "pointer"
    }),
};

export default function ContactUs(props) {
    const data = useStaticQuery(query);
    const { 
        allSanityContactUsReasons: { nodes: opt },
        allSanityTestimonials: { nodes: testimonials }
    } = data;

    const myPortableTextComponents = {
        block: {
            normal: ({ children }) => <p className="description">{children}</p>,
            h2: ({ children }) => <h2 className="heading2">{children}</h2>,
        }
    };

    const [resultForm, setResultForm] = useState("");
    const [status, setStatus] = useState("Contact Us");
    const [activeTestimonial, setActiveTestimonial] = useState(testimonials[0].company_abbreviation);

    useEffect(() => {
        setResultForm("");
    }, [props.show]);

    const handleSubmit = async (values, { resetForm }) => {
        setStatus("Submitting...");

        const newValues = {
            'entry.772377252': values.username,
            'entry.1285964399': values.company,
            'entry.1823105943': values.email,
            'entry.1651195108': values.reason
        };

        const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLScnEvrnrnubaxL_W2d-Wr30YQidY8wLgRu8NwY9PgLXvMOmTg/formResponse?";
        const params = new URLSearchParams(newValues);
        let url = baseUrl + params + "&submit=Submit";
        try {
            const response = await fetch(url, {
                method: "GET",  
                mode: "no-cors",
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            });
            resetForm({
                username: '',
                company: '',
                email: '',
                reason: ''
            });
            setResultForm("Thanks for contacting us. You should hear back from our team.");
            setStatus("Submit");
        } catch (err) {
            setStatus("Submit again!");
        }
    };

    const renderForm = (hidden) => {
        const style = hidden ? { display: 'none' } : {};
    
        return (
            <Formik
                initialValues={{
                    username: '',
                    company: '',
                    email: '',
                    reason: '',
                }}
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, handleChange, values, errors, setFieldValue, setErrors }) => (
                    <FormikForm style={style}>
                        <Form.Row>
                            <Form.Group as={Col} md="6" className="username-class">
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="First and last name"
                                        aria-describedby="inputGroupPrepend"
                                        name="username"
                                        value={values.username}
                                        onChange={(e) => {
                                            handleChange(e);
                                            let err = errors;
                                            delete err.username;
                                            setErrors(err);
                                        }}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username ? (<p> {errors.username} </p>) : null}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="company-field">
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Company"
                                        aria-describedby="inputGroupPrepend"
                                        name="company"
                                        value={values.company}
                                        onChange={(e) => {
                                            handleChange(e);
                                            let err = errors;
                                            delete err.company;
                                            setErrors(err);
                                        }}
                                        isInvalid={!!errors.company}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.company ? (<p> {errors.company} </p>) : null}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" className="email">
                                <InputGroup>
                                    <Form.Control
                                        type="email"
                                        placeholder="Work email"
                                        aria-describedby="inputGroupPrepend"
                                        name="email"
                                        value={values.email}
                                        onChange={(e) => {
                                            handleChange(e);
                                            let err = errors;
                                            delete err.email;
                                            setErrors(err);
                                        }}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email ? (<p> {errors.email} </p>) : null}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="reason">
                                <InputGroup>
                                    <Select
                                        name="reason"
                                        className={!!errors.reason ? "is-invalid" : ""}
                                        classNamePrefix="reason-select"
                                        options={opt.map((opt) => ({ value: opt.reason, label: opt.reason }))}
                                        isSearchable={false}
                                        placeholder="Reason for contacting"
                                        styles={selectStyles}
                                        onChange={(e) => {
                                            setFieldValue("reason", e.value);
                                            let err = errors;
                                            delete err.reason;
                                            setErrors(err);
                                        }}
                                        value={
                                            values.reason.length
                                                ? {
                                                    label: values.reason,
                                                    value: values.reason,
                                                }
                                                : null
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.reason ? (<p> {errors.reason} </p>) : null}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" style={{ marginBottom: "10px" }}>
                                <Button type="submit" className="contact-us-btn" disabled={isSubmitting}>{status}</Button>
                            </Form.Group>
                            {resultForm !== "" ?
                                <Form.Group as={Col} md="12">
                                    <p className="contact-result">{resultForm}</p>
                                </Form.Group> : null}
                            <Form.Group as={Col} md="12">
                            <p className="terms-privacy" style={{ marginBottom: 0 }}>
    By clicking the button, you agree to Sogody's <a href="/terms/">Terms of Service</a> and <a href="/privacy/">Privacy Policy</a>.
</p>
                            </Form.Group>
                            {/* New line with button */}
                            <Form.Group as={Col} md="12">
    <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
            Or, book a direct meeting
        </Modal.Title>
    </Modal.Header>

    <style jsx>{`
        @media (max-width: 767px) {
            .meet-sogody {
                margin: 0 auto;
            }
        }

        .meet-sogody {
            margin-bottom: 1rem;
        }
    `}</style>

    <button
        onClick={() => window.open("https://koalendar.com/e/meet-with-sogody?embed=true", "_blank")}
        className="meet-sogody"
    >
        Set up a meeting
        <img src={KoalendarLogo} alt="Koalendar logo" className="koalendar-img"/>
    </button>
</Form.Group>
                        </Form.Row>
                    </FormikForm>
                )}
            </Formik>
        );
    }

    return (
        <>
            {renderForm(true)}
            <Modal
                {...props}
                dialogClassName="modal-ctn"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="close-modal">
                    <Image width="auto"
                        height="30"
                        src={require('../assets/images/logo_sogody.png').default}
                        alt="Sogody logo"
                    />
                    <button onClick={props.onHide}> Close <img src={closeBtn} alt="close button" /></button>
                </div>
                <button className="desktop-close-button" onClick={props.onHide}> <img src={closeBtn} alt="close button" /></button>
                <div className={`testimonials-ctn ${activeTestimonial}`}>
                    <Slider {...settings} beforeChange={(current, next) => setActiveTestimonial(testimonials[next].company_abbreviation)}>
                        {
                            testimonials.map(({ _rawDescription, company, position, name, image, logo }, key) => (
                                <div className="testimonial" key={key}>
                                    <div>
                                        <img src={logo.asset.url} alt="company logo" className="company-logo"/>
                                        <PortableText value={_rawDescription} components={myPortableTextComponents}/>
                                    </div>
                                    <div className="person">
                                        {image && <img src={image.asset.url} alt="profile picture" />}
                                        <p className="username">{name}</p>
                                        <p className="position">{position}, {company}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
                <div className="modal-form">
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Contact Team at Sogody
                        </Modal.Title>
                        <p className="modal-header-paragraph">Looking for personalized help? In order to ensure you receive a quick reply, please fill out our Contact Us form.</p>
                    </Modal.Header>
                    <Modal.Body>
                        {renderForm()}
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}

export const query = graphql`
    {
        allSanityContactUsReasons(sort: {fields: _createdAt}) {
            nodes {
                reason
            }
        }
        allSanityTestimonials(sort: {fields: _createdAt}) {
            nodes {
                position
                name
                company
                company_abbreviation
                image {
                    asset {
                        url
                    }
                }
                logo {
                    asset {
                        url
                    }
                }
                _rawDescription
            }
        }
    }
`;